<template>
  <div class="container">
    <b-overlay :show="isLoading">
      <vue-pdf-embed
        :source="loadPdf()"
        @rendered="isLoading = false"
      />
    </b-overlay>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import legalNoticesFr from '@/assets/pdf/DIVEMA-mentions-légales.pdf'

export default defineComponent({
  name: 'LegalNotices',

  components: {
    VuePdfEmbed,
  },
  setup(_props, ctx) {
    const { $store } = ctx.root

    const legalNotices = {
      fr: legalNoticesFr,
    }

    const isLoading = ref(true)

    const loadPdf = () => legalNotices[$store.state.appConfig.lang] || legalNotices.fr

    return {
      loadPdf,
      isLoading,
    }
  },
})
</script>
